$(function(){

  const mySwiper = new Swiper ('.swiper-container', {
    // Optional parameters
    effect: 'slide',
    // スライダーの方向を指定（'horizontal' or 'vertical'）
    direction: 'horizontal',

    // スライドのスピード（ミリ秒）
    speed: 600,

    // オートプレイ
    autoplay: {
      delay: 3000,
    },

    loop: true,
  });

});
